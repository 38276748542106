import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpModule } from "@angular/http";

//routing
import { AppRoutingModule } from "./app-routing.module";

//components
import { SidenavComponent } from "./components/sidenav/sidenav.component";
import { ToastComponent } from "./components/toast/toast.component";
import { AppComponent } from "./app.component";


//interceptors
import { JwtInterceptor } from "./shared/helpers/jwt.interceptor";
import { DevisComponent } from './customer/devis/devis.component';
import { DatePipe } from "@angular/common";
import { AddClientsComponent } from './gestionproduction/clients/add-clients/add-clients.component';
import { AddProviderComponent } from './gestionproduction/providers/add-provider/add-provider.component';
import { EditProviderComponent } from './gestionproduction/providers/edit-provider/edit-provider.component';
import { EditClientComponent } from './gestionproduction/clients/edit-client/edit-client.component';


@NgModule({
  declarations: [AppComponent, SidenavComponent, ToastComponent, DevisComponent,],
  imports: [

    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
